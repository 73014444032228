import React, { FC } from 'react';
import styled from 'styled-components';
import GatsbyImage from '../../gatsby/GatsbyImage';

interface HomeWorkMore extends PropsWithStyle {
  /** flag for mobile UX */
  isMobile?: boolean;
}

const HomeWorkMore: FC<HomeWorkMore> = ({
  style = {},
  className = '',
  isMobile = false,
}: HomeWorkMore) => {
  return (
    <HomeWorkMoreContainer style={style} className={className}>
      <div style={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: `${isMobile ? 100 : (389 / 603) * 100}%`,
          }}
        >
          <GatsbyImage imagePath={isMobile ? 'more-work-mobile.png' : 'more-work-desktop.png'} />
          <div
            style={{
              width: '100%',
              paddingTop: `max(${isMobile ? (2 / 234) * 100 : (2 / 603) * 100}%, 1px)`,
              background: 'none',
            }}
          />
        </div>
        <a
          href="//www.ghostpxl.com"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: `${isMobile ? 100 : (214 / 603) * 100}%`,
          }}
        >
          <GatsbyImage imagePath={isMobile ? 'ghostpxl-mobile.png' : 'ghostpxl-desktop.png'} />
          <div
            style={{
              width: '100%',
              paddingTop: `max(${isMobile ? (2 / 234) * 100 : (2 / 603) * 100}%, 1px)`,
              background: 'white',
            }}
          />
        </a>
      </div>
      <p
        style={{
          width: `${isMobile ? 100 : (314 / 603) * 100}%`,
          marginTop: `${isMobile ? (29 / 234) * 100 : (12 / 603) * 100}%`,
          fontFamily: 'Replica-Bold',
        }}
      >
        WHERE I CURRENTLY LEAD A TALENTED GROUP OF CREATIVES.
      </p>
    </HomeWorkMoreContainer>
  );
};

const HomeWorkMoreContainer = styled.div`
  position: relative;
`;

export default HomeWorkMore;
