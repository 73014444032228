import React, { FC, useRef } from 'react';
import styled from 'styled-components';

import GatsbyImage from '../../gatsby/GatsbyImage';
import { deviceSizes } from '../../styles/mediaDevice';
import useComponentSize from '../../hooks/useComponentSize';
import InstagramIcon from '../atoms/InstagramIcon';

interface HomeHeroProps extends PropsWithStyle {
  /** when hero section is prepared in DOM */
  onReady?: () => void;
}

const HomeHero: FC<HomeHeroProps> = ({
  style = { width: '100%' },
  onReady = (): void => {
    // do nothing
  },
}: HomeHeroProps) => {
  const ref = useRef(null);
  const componentSize = useComponentSize(ref);
  const { width } = componentSize;

  let height: number | undefined;

  const breakpoint = deviceSizes.S;

  if (!width) {
    height = undefined;
  } else if (width < breakpoint) {
    // mobile
    height = (width * 773) / 375;
  } else {
    // desktop
    height = (width * 863) / 1440;
  }

  if (width) {
    onReady();
  }

  return (
    <HomeHeroContainer style={{ height, ...style }} ref={ref}>
      {width && (
        <>
          <HomeHeroBackgroundImageContainer>
            <GatsbyImage
              imagePath={
                width < breakpoint ? 'home-hero-bg-mobile.jpg' : 'home-hero-bg-desktop.jpg'
              }
              sizeType="XXL"
              loading="eager"
            />
          </HomeHeroBackgroundImageContainer>
          {width < breakpoint && (
            <HomeHeroLogoImageContainerMobile>
              <GatsbyImage imagePath="logo.png" loading="eager" />
            </HomeHeroLogoImageContainerMobile>
          )}
          {width >= breakpoint && (
            <HomeHeroLogoImageContainerDesktop>
              <GatsbyImage imagePath="logo.png" loading="eager" />
            </HomeHeroLogoImageContainerDesktop>
          )}

          <HomeHeroTextContainer
            style={
              width < breakpoint
                ? {
                    width: `${(323 / 375) * 100}%`,
                    left: `${(33 / 375) * 100}%`,
                    top: `${(356 / 773) * 100}%`,
                  }
                : {
                    width: `${(631 / 1440) * 100}%`,
                    left: `${(135 / 1440) * 100}%`,
                    top: `${(345 / 863) * 100}%`,
                  }
            }
          >
            <GatsbyImage
              imagePath="home-hero-text-primary.png"
              loading="eager"
              objectFit="contain"
            />
            <GatsbyImage
              imagePath="home-hero-text-secondary.png"
              loading="eager"
              style={{
                width: width < breakpoint ? `${(275 / 323) * 100}%` : `${(412 / 629) * 100}%`,
              }}
            />
          </HomeHeroTextContainer>
          <HomeHeroInstagramIconContainer
            style={
              width < breakpoint
                ? {
                    width: `${(73 / 375) * 100}%`,
                    left: `${(26 / 375) * 100}%`,
                    top: `${(267 / 773) * 100}%`,
                  }
                : {
                    width: `${(100 / 1440) * 100}%`,
                    left: `${(127 / 1440) * 100}%`,
                    top: `${(631 / 863) * 100}%`,
                  }
            }
          >
            <InstagramIcon
              linkedinUrl="https://www.linkedin.com/in/jordankleincd"
              instagramUrl="https://www.instagram.com/ghostpxl"
            />
          </HomeHeroInstagramIconContainer>
        </>
      )}
    </HomeHeroContainer>
  );
};

const HomeHeroContainer = styled.div`
  position: relative;
`;

const HomeHeroBackgroundImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.8;
  z-index: 1;
  & * {
    z-index: 1;
  }
`;

const HomeHeroLogoImageContainerDesktop = styled.div`
  position: absolute;
  left: ${(135 / 1440) * 100}%;
  top: ${(100 / 863) * 100}%;
  width: ${(201 / 1440) * 100}%;
  z-index: 2;
  & * {
    z-index: 2;
  }
`;

const HomeHeroLogoImageContainerMobile = styled.div`
  position: absolute;
  left: ${(33 / 375) * 100}%;
  top: ${(55 / 773) * 100}%;
  transform-origin: top right;
  width: ${(169.26 / 375) * 100}%;
  transform: translate(-100%, 0) rotate(270deg);
  z-index: 2;
  & * {
    z-index: 2;
  }
`;

const HomeHeroTextContainer = styled.div`
  position: absolute;
  z-index: 2;
  & * {
    z-index: 2;
  }
`;

const HomeHeroInstagramIconContainer = styled.div`
  position: absolute;
  z-index: 2;
  & * {
    z-index: 2;
  }
`;

export default HomeHero;
