import React, { FC, ReactNode, useRef } from 'react';

import SectionTitle from '../atoms/SectionTitle';
import useComponentSize from '../../hooks/useComponentSize';
import { deviceSizes } from '../../styles/mediaDevice';

interface HomeSectionTemplateProps extends PropsWithStyle {
  /** image path in relative to the image source directory
   *  use only on mobile if imagePathDesktop is given, otherwise, use on both mobile and desktop */
  imagePath: string;

  /** image path in relative to the image source directory */
  imagePathDesktop?: string;

  /** style from design */
  designedStyle: {
    title: {
      width: ResponsiveProps<number>;
      gap: {
        top: ResponsiveProps<number>;
        bottom: ResponsiveProps<number>;
        left: ResponsiveProps<number>;
      };
    };
  };
  /** child nodes */
  children?: ReactNode;
}

const HomeSectionTemplate: FC<HomeSectionTemplateProps> = ({
  imagePath,
  designedStyle,
  style = {},
  className = '',
  children = null,
  imagePathDesktop = undefined,
}: HomeSectionTemplateProps) => {
  const ref = useRef(null);
  const { width = 0 } = useComponentSize(ref);

  const isMobile = width < deviceSizes.S;
  const calculateResponsiveSize = (designedWidth: ResponsiveProps<number>): string =>
    `${isMobile ? (designedWidth.mobile / 375) * width : (designedWidth.desktop / 1440) * width}px`;
  const titleWidth = calculateResponsiveSize(designedStyle.title.width);
  const titleGapTop = calculateResponsiveSize(designedStyle.title.gap.top);
  const titleGapBottom = calculateResponsiveSize(designedStyle.title.gap.bottom);
  const titleGapLeft = calculateResponsiveSize(designedStyle.title.gap.left);

  return (
    <div
      style={{ position: 'relative', zIndex: 2, width: '100%', ...style }}
      className={className}
      ref={ref}
    >
      <div style={{ height: titleGapTop }} />
      <SectionTitle
        imagePath={imagePathDesktop && !isMobile ? imagePathDesktop : imagePath}
        style={{ marginLeft: titleGapLeft, width: titleWidth, overflow: 'hidden' }}
      />
      <div style={{ height: titleGapBottom }} />
      {children}
    </div>
  );
};

export default HomeSectionTemplate;
