import React, { FC, useRef } from 'react';
import useComponentSize from '../../hooks/useComponentSize';
import { deviceSizes } from '../../styles/mediaDevice';
import HomeSectionTemplate from '../templates/HomeSectionTemplate';
import GatsbyImage from '../../gatsby/GatsbyImage';

const sectionDesignedStyle = {
  title: {
    width: { mobile: 217, desktop: 248 },
    gap: {
      top: { mobile: 80, desktop: 92 },
      bottom: { mobile: 35, desktop: 45 },
      left: { mobile: 33, desktop: 137 },
    },
    imagePath: {
      mobile: 'title-leadership-mobile.png',
      desktop: 'title-leadership-desktop.png',
    },
  },
  subtitle: {
    width: { mobile: 301, desktop: 604 },
    left: { mobile: 33, desktop: 139 },
    bottom: { mobile: 10, desktop: 12 },
  },
  text: {
    width: { mobile: 301, desktop: 604 },
    left: { mobile: 33, desktop: 139 },
    size: { mobile: 12, desktop: 14 },
    lineHeight: { mobile: 14, desktop: 17 },
    bottom: { mobile: 30, desktop: 40 },
  },
  image: {
    desktop: {
      width: 586,
      top: -133,
    },
    mobile: {
      top: 42,
    },
  },
  bottom: { mobile: 60, desktop: 50 },
};

const HomeLeadershipSection: FC<PropsWithStyle> = ({
  style = {},
  className = '',
}: PropsWithStyle) => {
  const ref = useRef(null);
  const { width = 0 } = useComponentSize(ref);

  const isMobile = width < deviceSizes.S;

  const calculateResponsiveSize = (designedSize: ResponsiveProps<number>): string =>
    `${isMobile ? (designedSize.mobile / 375) * width : (designedSize.desktop / 1440) * width}px`;

  const Subtitle: FC<{ imagePaths: ResponsiveProps<string>; children: React.ReactNode }> = ({
    imagePaths,
    children = null,
  }: {
    imagePaths: ResponsiveProps<string>;
    children: React.ReactNode;
  }) => (
    <div
      style={{
        width: calculateResponsiveSize(sectionDesignedStyle.subtitle.width),
        marginLeft: calculateResponsiveSize(sectionDesignedStyle.subtitle.left),
        fontSize: calculateResponsiveSize(sectionDesignedStyle.text.size),
        lineHeight: calculateResponsiveSize(sectionDesignedStyle.text.lineHeight),
      }}
    >
      <GatsbyImage imagePath={isMobile ? imagePaths.mobile : imagePaths.desktop} />
      <div
        style={{
          height: calculateResponsiveSize(sectionDesignedStyle.subtitle.bottom),
        }}
      />
      <p>{children}</p>
      <div
        style={{
          height: calculateResponsiveSize(sectionDesignedStyle.text.bottom),
        }}
      />
    </div>
  );

  return (
    <HomeSectionTemplate
      imagePath={sectionDesignedStyle.title.imagePath.mobile}
      imagePathDesktop={sectionDesignedStyle.title.imagePath.desktop}
      designedStyle={sectionDesignedStyle}
      style={{ width: '100%', ...style }}
      className={className}
    >
      <div ref={ref} style={{ width: '100%', position: 'relative', overflow: 'visible' }}>
        <Subtitle
          imagePaths={{
            mobile: 'leadership-envision-mobile.png',
            desktop: 'leadership-envision-desktop.png',
          }}
        >
          What should tomorrow be like for my team, not just tomorrow a long term mindset and view.
          The moon shot goals and purposes of the work. Envisioning a different reality in the
          future than we experience today.
        </Subtitle>
        <Subtitle
          imagePaths={{
            mobile: 'leadership-enlist-mobile.png',
            desktop: 'leadership-enlist-desktop.png',
          }}
        >
          Enlisting others to share their voices and perspective. People support what they create.
          If people are involved in the ideation of the vision. Brainstorming what is it that we are
          about, what do we stand for and where are we going. Enjoying the process. Asking
          questions, paying attention to their needs.
        </Subtitle>
        <Subtitle
          imagePaths={{
            mobile: 'leadership-empower-mobile.png',
            desktop: 'leadership-empower-desktop.png',
          }}
        >
          Empowerment gives people the decisions making authority and trust to work towards the
          vision. Equip them with the knowledge, the technology, the tools and training. To march
          together towards the common goal. This is a continuing practice not a one time thing.
        </Subtitle>
        {isMobile ? (
          <GatsbyImage
            style={{
              marginTop: `${(sectionDesignedStyle.image.mobile.top / 375) * width}px`,
              display: width > 0 ? 'block' : 'none',
            }}
            imagePath="leadership-image-mobile.jpg"
          />
        ) : (
          <GatsbyImage
            style={{
              width: `${(sectionDesignedStyle.image.desktop.width / 1440) * width}px`,
              position: 'absolute',
              right: 0,
              top: `${(sectionDesignedStyle.image.desktop.top / 1440) * width}px`,
              display: width > 0 ? 'block' : 'none',
            }}
            imagePath="leadership-image-desktop.jpg"
          />
        )}
        <div style={{ height: calculateResponsiveSize(sectionDesignedStyle.bottom) }} />
      </div>
    </HomeSectionTemplate>
  );
};

export default HomeLeadershipSection;
