import React, { FC, useRef } from 'react';
import useComponentSize from '../../hooks/useComponentSize';
import { deviceSizes } from '../../styles/mediaDevice';
import HomeSectionTemplate from '../templates/HomeSectionTemplate';
import GatsbyImage from '../../gatsby/GatsbyImage';

const sectionDesignedStyle = {
  title: {
    width: { mobile: 217, desktop: 360 },
    gap: {
      top: { mobile: 77, desktop: 150 },
      bottom: { mobile: 30, desktop: 0 },
      left: { mobile: 32, desktop: 136 },
    },
    imagePath: {
      mobile: 'title-rolling-mobile.png',
      desktop: 'title-rolling-desktop.png',
    },
  },
  content: {
    text: {
      width: { mobile: 307, desktop: 604 },
      left: { mobile: 32, desktop: 136 },
      size: { mobile: 14, desktop: 14 },
      lineHeight: { mobile: 17, desktop: 17 },
    },
    image: {
      width: { mobile: 296, desktop: 1057 },
      top: { mobile: 59, desktop: 35 },
      bottom: { mobile: 100, desktop: 172 },
      left: { mobile: 32, desktop: 138 },
    },
  },
};

const HomeRollingSection: FC<PropsWithStyle> = ({ style = {}, className = '' }: PropsWithStyle) => {
  const ref = useRef(null);
  const { width = 0 } = useComponentSize(ref);

  const isMobile = width < deviceSizes.S;

  const calculateResponsiveSize = (designedSize: ResponsiveProps<number>): string =>
    `${isMobile ? (designedSize.mobile / 375) * width : (designedSize.desktop / 1440) * width}px`;

  return (
    <HomeSectionTemplate
      imagePath={sectionDesignedStyle.title.imagePath.mobile}
      imagePathDesktop={sectionDesignedStyle.title.imagePath.desktop}
      designedStyle={sectionDesignedStyle}
      style={{ width: '100%', ...style }}
      className={className}
    >
      <div ref={ref} style={{ width: '100%', position: 'relative' }}>
        <p
          style={{
            fontFamily: 'Replica-Bold',
            width: calculateResponsiveSize(sectionDesignedStyle.content.text.width),
            marginLeft: calculateResponsiveSize(sectionDesignedStyle.content.text.left),
            fontSize: calculateResponsiveSize(sectionDesignedStyle.content.text.size),
            lineHeight: calculateResponsiveSize(sectionDesignedStyle.content.text.lineHeight),
          }}
        >
          10+ YEARS OF EXPERIENCE ACROSS MULTIPLE DESIGN SOFTWARES.
          <br />I LIKE TO DIVE IN AND GET MY HANDS DIRTY TO PUSH DELIVERABLES TO COMPLETION.
        </p>
        <div style={{ height: calculateResponsiveSize(sectionDesignedStyle.content.image.top) }} />
        <GatsbyImage
          style={{
            width: calculateResponsiveSize(sectionDesignedStyle.content.image.width),
            marginLeft: calculateResponsiveSize(sectionDesignedStyle.content.image.left),
          }}
          imagePath={isMobile ? 'all-tools-mobile.png' : 'all-tools-desktop.png'}
        />
        <div
          style={{ height: calculateResponsiveSize(sectionDesignedStyle.content.image.bottom) }}
        />
      </div>
    </HomeSectionTemplate>
  );
};

export default HomeRollingSection;
