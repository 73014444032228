import React, { FC, useRef } from 'react';

import useComponentSize from '../../hooks/useComponentSize';
import { deviceSizes } from '../../styles/mediaDevice';
import HomeSectionTemplate from '../templates/HomeSectionTemplate';

const sectionDesignedStyle = {
  title: {
    width: { mobile: 286, desktop: 236 },
    gap: {
      top: { mobile: 115, desktop: 85 },
      bottom: { mobile: 4, desktop: 29 },
      left: { mobile: 33, desktop: 138 },
    },
  },
};

const textDesignedStyle = {
  accent: {
    width: { mobile: 328, desktop: 1440 - 135 * 2 },
    gap: {
      left: { mobile: 33, desktop: 135 },
      bottom: { mobile: 32, desktop: 13 },
    },
    font: {
      size: { mobile: 14, desktop: 14 },
      lineHeight: { mobile: 17, desktop: 17 },
    },
  },
  primary: {
    width: { mobile: 305, desktop: 1440 - 135 * 2 },
    gap: {
      left: { mobile: 33, desktop: 135 },
      bottom: { mobile: 44, desktop: 79 },
    },
    font: {
      size: { mobile: 18, desktop: 18 },
      lineHeight: { mobile: 24, desktop: 24 },
    },
  },
  secondary: {
    width: { mobile: 305, desktop: 1440 - 135 * 2 },
    gap: {
      left: { mobile: 33, desktop: 135 },
      bottom: { mobile: 65, desktop: 102 },
    },
    font: {
      size: { mobile: 18, desktop: 18 },
      lineHeight: { mobile: 24, desktop: 24 },
    },
  },
};

const HomeAboutSection: FC<PropsWithStyle> = ({ style = {}, className = '' }: PropsWithStyle) => {
  const ref = useRef(null);
  const { width = 0 } = useComponentSize(ref);

  const isMobile = width < deviceSizes.S;

  const calculateResponsiveSize = (designedSize: ResponsiveProps<number>): string =>
    `${isMobile ? (designedSize.mobile / 375) * width : (designedSize.desktop / 1440) * width}px`;

  return (
    <HomeSectionTemplate
      imagePath="title-a-bit-about-me.png"
      designedStyle={sectionDesignedStyle}
      style={{ width: '100%', ...style }}
      className={className}
    >
      <div style={{ width: '100%' }} ref={ref}>
        <p
          style={{
            fontFamily: 'Replica-Bold',
            marginLeft: calculateResponsiveSize(textDesignedStyle.accent.gap.left),
            fontSize: calculateResponsiveSize(textDesignedStyle.accent.font.size),
            width: calculateResponsiveSize(textDesignedStyle.accent.width),
          }}
        >
          PASSIONATE ABOUT POSITIVELY INFLUENCING OTHERS TO RISE TO THEIR POTENTIAL.
        </p>
        <div style={{ height: calculateResponsiveSize(textDesignedStyle.accent.gap.bottom) }} />
        <p
          style={{
            marginLeft: calculateResponsiveSize(textDesignedStyle.primary.gap.left),
            fontSize: calculateResponsiveSize(textDesignedStyle.primary.font.size),
            width: calculateResponsiveSize(textDesignedStyle.primary.width),
          }}
        >
          I bring 10+ years experience as a Creative Director delivering impactful and innovative
          ideas for global brands.
          <br />I pride myself on being a creative leader in developing a cohesive brand vision,
          across new media, digital channels and marketing initiatives.
          <br />I thrive on cross leading creative and development teams to execute the companies
          goals for the products, design, architecture and scalability.
        </p>
        <div style={{ height: calculateResponsiveSize(textDesignedStyle.primary.gap.bottom) }} />
        <p
          style={{
            marginLeft: calculateResponsiveSize(textDesignedStyle.secondary.gap.left),
            fontSize: calculateResponsiveSize(textDesignedStyle.secondary.font.size),
            width: calculateResponsiveSize(textDesignedStyle.secondary.width),
          }}
        >
          Side note - I love music, surfing, and cooking.
        </p>
        <div style={{ height: calculateResponsiveSize(textDesignedStyle.secondary.gap.bottom) }} />
      </div>
    </HomeSectionTemplate>
  );
};

export default HomeAboutSection;
