import React, { FC, useRef } from 'react';
import useComponentSize from '../../hooks/useComponentSize';
import { deviceSizes } from '../../styles/mediaDevice';
import HomeSectionTemplate from '../templates/HomeSectionTemplate';
// import GatsbyImage from '../../gatsby/GatsbyImage';
// import resumeSrc from '../../assets/resume.pdf';
import InstagramIcon from '../atoms/InstagramIcon';

const sectionDesignedStyle = {
  title: {
    width: { mobile: 164, desktop: 164 },
    gap: {
      top: { mobile: 64, desktop: 129 },
      bottom: { mobile: 12, desktop: 14 },
      left: { mobile: 33, desktop: 139 },
    },
    imagePath: {
      mobile: 'title-contact.png',
      desktop: 'title-contact.png',
    },
  },
  content: {
    text: {
      width: { mobile: 292, desktop: 604 },
      left: { mobile: 33, desktop: 139 },
      size: { mobile: 14, desktop: 14 },
      lineHeight: { mobile: 17, desktop: 17 },
      bottom: { mobile: 56, desktop: 60 },
    },
    contact: {
      width: { mobile: 316, desktop: 390 },
      left: { mobile: 33, desktop: 139 },
      size: { mobile: 12, desktop: 12 },
      lineHeight: { mobile: 12, desktop: 12 },
      bottom: { mobile: 12, desktop: 20 },
    },
    download: {
      width: { mobile: 249, desktop: 414 },
      top: { mobile: 40, desktop: 52 },
      left: { mobile: 33, desktop: 138 },
      bottom: { mobile: 28, desktop: 72 },
    },
    instagram: {
      width: { mobile: 72, desktop: 95 },
      left: { mobile: 26, desktop: 130 },
      bottom: { mobile: 150, desktop: 150 },
    },
  },
};

const HomeContactSection: FC<PropsWithStyle> = ({ style = {}, className = '' }: PropsWithStyle) => {
  const ref = useRef(null);
  const { width = 0 } = useComponentSize(ref);

  const isMobile = width < deviceSizes.S;

  const calculateResponsiveSize = (designedSize: ResponsiveProps<number>): string =>
    `${isMobile ? (designedSize.mobile / 375) * width : (designedSize.desktop / 1440) * width}px`;

  const contactStyle = {
    display: 'block',
    fontFamily: 'PragmaticaExtended-Black',
    width: calculateResponsiveSize(sectionDesignedStyle.content.contact.width),
    fontSize: calculateResponsiveSize(sectionDesignedStyle.content.contact.size),
    lineHeight: calculateResponsiveSize(sectionDesignedStyle.content.contact.lineHeight),
    marginLeft: calculateResponsiveSize(sectionDesignedStyle.content.contact.left),
    marginBottom: calculateResponsiveSize(sectionDesignedStyle.content.contact.bottom),
  };

  return (
    <HomeSectionTemplate
      imagePath={sectionDesignedStyle.title.imagePath.mobile}
      imagePathDesktop={sectionDesignedStyle.title.imagePath.desktop}
      designedStyle={sectionDesignedStyle}
      style={{ width: '100%', ...style }}
      className={className}
    >
      <div ref={ref} style={{ width: '100%', position: 'relative' }}>
        <p
          style={{
            fontFamily: 'Replica-Bold',
            width: calculateResponsiveSize(sectionDesignedStyle.content.text.width),
            marginLeft: calculateResponsiveSize(sectionDesignedStyle.content.text.left),
            fontSize: calculateResponsiveSize(sectionDesignedStyle.content.text.size),
            lineHeight: calculateResponsiveSize(sectionDesignedStyle.content.text.lineHeight),
          }}
        >
          I WOULD LOVE TO SEE HOW I COULD BRING VALUE AND LEADERSHIP TO YOUR CORPORATIONS CREATIVE
          INITIATIVES.
        </p>
        <div
          style={{ height: calculateResponsiveSize(sectionDesignedStyle.content.text.bottom) }}
        />
        <p style={contactStyle}>213.393.4680</p>
        <a href="mailto:jordan.klein@ghostpxl.com" style={contactStyle}>
          JORDAN.KLEIN@GHOSTPXL.COM
        </a>
        <a
          href="//jordankleincd.com"
          target="_blank"
          rel="noopener noreferrer"
          style={contactStyle}
        >
          JORDANKLEINCD.COM
        </a>
        {/* <a
          href={resumeSrc}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            display: 'block',
            width: calculateResponsiveSize(sectionDesignedStyle.content.download.width),
            marginTop: calculateResponsiveSize(sectionDesignedStyle.content.download.top),
            marginLeft: calculateResponsiveSize(sectionDesignedStyle.content.download.left),
            marginBottom: calculateResponsiveSize(sectionDesignedStyle.content.download.bottom),
          }}
        >
          <GatsbyImage
            imagePath={isMobile ? 'download-resume-mobile.png' : 'download-resume-desktop.png'}
          />
        </a> */}
        <InstagramIcon
          linkedinUrl="https://www.linkedin.com/in/jordankleincd"
          instagramUrl="https://www.instagram.com/ghostpxl"
          style={{
            width: calculateResponsiveSize(sectionDesignedStyle.content.instagram.width),
            marginLeft: calculateResponsiveSize(sectionDesignedStyle.content.instagram.left),
          }}
        />
        <div
          style={{ height: calculateResponsiveSize(sectionDesignedStyle.content.instagram.bottom) }}
        />
      </div>
    </HomeSectionTemplate>
  );
};

export default HomeContactSection;
