import React, { FC, useRef, ReactNode } from 'react';
import styled from 'styled-components';

import HomeWorkTile from './HomeWorkTile';
import useComponentSize from '../../hooks/useComponentSize';

interface HomeWorkTileListProps extends PropsWithStyle {
  /** image path (grayscale) */
  data: WorkTileData[];
  /** flag for mobile UX */
  isMobile?: boolean;
}

const HomeWorkTileList: FC<HomeWorkTileListProps> = ({
  data,
  isMobile = false,
  style = {},
  className = '',
}: HomeWorkTileListProps) => {
  const ref = useRef(null);
  const { width = 0 } = useComponentSize(ref);
  const tileWidth = isMobile ? width : width / 3;
  const tileHeight = (246 / 480) * tileWidth;
  return (
    <HomeWorkTileListContainer style={style} className={className} ref={ref}>
      {data.map(
        ({ id, name, work }: WorkTileData): ReactNode => {
          const imagePathGray = `work/${id}/tile-gray.jpg`;
          const imagePathColor = `work/${id}/tile-color.jpg`;
          const navTo = `work/${id}`;
          return (
            <HomeWorkTile
              key={`work-tile-${id}`}
              imagePathGray={imagePathGray}
              imagePathColor={imagePathColor}
              name={name}
              work={work}
              navTo={navTo}
              style={{ width: `${tileWidth}px`, height: `${tileHeight}px` }}
              isMobile={isMobile}
            />
          );
        },
      )}
    </HomeWorkTileListContainer>
  );
};

export default HomeWorkTileList;

const HomeWorkTileListContainer = styled.div`
  position: relative;
  user-select: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
`;
