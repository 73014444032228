import React, { FC, useRef } from 'react';
import useComponentSize from '../../hooks/useComponentSize';
import { deviceSizes } from '../../styles/mediaDevice';
import HomeSectionTemplate from '../templates/HomeSectionTemplate';
import GatsbyImage from '../../gatsby/GatsbyImage';

const sectionDesignedStyle = {
  title: {
    width: { mobile: 255, desktop: 341 },
    gap: {
      top: { mobile: 71, desktop: 146 },
      bottom: { mobile: 53, desktop: 113 },
      left: { mobile: 33, desktop: 139 },
    },
    imagePath: {
      mobile: 'title-brand-mobile.png',
      desktop: 'title-brand-desktop.png',
    },
  },
  content: {
    width: { mobile: 315, desktop: 1197 },
    gap: {
      bottom: { mobile: 100, desktop: 152 },
      left: { mobile: 28, desktop: 136 },
    },
  },
};

const HomeBrandSection: FC<PropsWithStyle> = ({ style = {}, className = '' }: PropsWithStyle) => {
  const ref = useRef(null);
  const { width = 0 } = useComponentSize(ref);

  const isMobile = width < deviceSizes.S;

  const calculateResponsiveSize = (designedSize: ResponsiveProps<number>): string =>
    `${isMobile ? (designedSize.mobile / 375) * width : (designedSize.desktop / 1440) * width}px`;

  return (
    <HomeSectionTemplate
      imagePath={sectionDesignedStyle.title.imagePath.mobile}
      imagePathDesktop={sectionDesignedStyle.title.imagePath.desktop}
      designedStyle={sectionDesignedStyle}
      style={{ width: '100%', ...style }}
      className={className}
    >
      <div ref={ref} style={{ width: '100%', position: 'relative' }}>
        <GatsbyImage
          style={{
            width: calculateResponsiveSize(sectionDesignedStyle.content.width),
            marginLeft: calculateResponsiveSize(sectionDesignedStyle.content.gap.left),
          }}
          imagePath={isMobile ? 'all-brands-mobile.png' : 'all-brands-desktop.png'}
        />
        <div style={{ height: calculateResponsiveSize(sectionDesignedStyle.content.gap.bottom) }} />
      </div>
    </HomeSectionTemplate>
  );
};

export default HomeBrandSection;
