import React, { FC, useState } from 'react';
import styled from 'styled-components';

import HomeHero from '../atomic/organisms/HomeHero';
import Separator from '../atomic/atoms/Separator';
import Media from '../styles/mediaDevice';
import HomeAboutSection from '../atomic/organisms/HomeAboutSection';
import HomeWorkSection from '../atomic/organisms/HomeWorkSection';
import HomeBrandSection from '../atomic/organisms/HomeBrandSection';
import HomeRollingSection from '../atomic/organisms/HomeRollingSection';
import HomeLeadershipSection from '../atomic/organisms/HomeLeadershipSection';
import HomeContactSection from '../atomic/organisms/HomeContactSection';
import PageTemplate from '../atomic/templates/PageTemplate';

const HomePage: FC = () => {
  const [heroReady, setHeroReady] = useState(false);
  return (
    <PageTemplate title="Home">
      <HomeHero
        onReady={(): void => {
          if (!heroReady) {
            setHeroReady(true);
          }
        }}
      />
      {heroReady && (
        <>
          <ResponsiveSeparator />
          <HomeAboutSection />
          <ResponsiveSeparator />
          <HomeWorkSection />
          <ResponsiveSeparator />
          <HomeBrandSection />
          <ResponsiveSeparator />
          <HomeRollingSection />
          <ResponsiveSeparator />
          <HomeLeadershipSection />
          <ResponsiveSeparator />
          <HomeContactSection />
        </>
      )}
    </PageTemplate>
  );
};

const ResponsiveSeparator = styled(Separator)`
  margin-left: ${(34 / 375) * 100}%;
  width: ${(68 / 375) * 100}%;
  height: ${(4 / 375) * 100}vw;
  ${Media.S`
    margin-left: ${(135 / 1440) * 100}%;
    width: ${(68 / 1440) * 100}%;
    height: min(${(4 / 1440) * 100}vw, 4px);
  `}
`;

export default HomePage;
