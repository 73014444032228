import React, { FC } from 'react';
import styled from 'styled-components';

const Separator: FC<PropsWithStyle> = ({ style = {}, className = '' }: PropsWithStyle) => {
  return <DefaultSeparator style={style} className={className} />;
};

const DefaultSeparator = styled.div`
  position: relative;
  width: 68px;
  height: 4px;
  background: #ffe42a;
  z-index: 2;
`;

export default Separator;
