import React, { FC, useRef } from 'react';

import styled from 'styled-components';
import useComponentSize from '../../hooks/useComponentSize';
import { deviceSizes } from '../../styles/mediaDevice';
import HomeSectionTemplate from '../templates/HomeSectionTemplate';
import HomeWorkTileList from '../molecules/HomeWorkTileList';
import HomeWorkMore from '../molecules/HomeWorkMore';

const sectionDesignedStyle = {
  title: {
    width: { mobile: 327, desktop: 1033 },
    gap: {
      top: { mobile: 71, desktop: 94 },
      bottom: { mobile: 33, desktop: 49 },
      left: { mobile: 33, desktop: 137 },
    },
    imagePath: {
      mobile: 'title-highlighted-work-mobile.png',
      desktop: 'title-highlighted-work-desktop.png',
    },
  },
  more: {
    width: { mobile: 234, desktop: 603 },
    gap: {
      top: { mobile: 84, desktop: 159 },
      bottom: { mobile: 85, desktop: 149 },
      left: { mobile: 33, desktop: 136 },
    },
    text: {
      size: { mobile: 14, desktop: 14 },
      lineHeight: { mobile: 17, desktop: 17 },
    },
  },
};

const tileListData: WorkTileData[] = [
  {
    id: 'j-cole',
    name: 'J.COLE',
    work: 'CREATIVE DIRECTION',
  },
  {
    id: 'zayn',
    name: 'ZAYN',
    work: 'UX, AE, ART DIRECTION',
  },
  {
    id: 'justin-bieber',
    name: 'JUSTIN BIEBER',
    work: 'FASHION DESIGN, CREATIVE DIRECTION',
  },
  {
    id: 'bon-appetit',
    name: 'BON APPETIT',
    work: 'BRAND DEVELOPMENT, GRAPHIC DESIGN',
  },
  {
    id: 'minamoto',
    name: 'MINAMOTO',
    work: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION',
  },
  {
    id: 'carmines',
    name: 'CARMINES',
    work: 'INTERIOR DESIGN, BRAND DEVELOPMENT',
  },
  {
    id: 'h-plus',
    name: 'H+',
    work: 'UX, BRAND DEVELOPMENT',
  },
  {
    id: 'invigorade',
    name: 'INVIGORADE',
    work: 'PACKAGING, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION',
  },
  {
    id: 'way-up',
    name: 'WAY UP',
    work: 'INTERIOR DESIGN, BRAND DEVELOPMENT, PACKAGING DESIGN',
  },
  {
    id: 'ics',
    name: 'ICS',
    work: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT',
  },
  {
    id: 'skype',
    name: 'skype',
    work: 'INDUSTRIAL DESIGN, UX DESIGN, SOFTWARE DEVELOPMENT CONSULTING',
  },
  {
    id: 'restore',
    name: 'restore',
    work: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, PRODUCT DEVELOPMENT',
  },
  {
    id: 'move',
    name: 'move',
    work: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION, PRINT DESIGN',
  },
  {
    id: 'green-light',
    name: 'green light',
    work: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, PRODUCT DESIGN',
  },
  {
    id: 'vimocity',
    name: 'vimocity',
    work: 'BRANDING, UX, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION',
  },
  {
    id: 'ever',
    name: 'ever',
    work: 'PRODUCT DEVELOPMENT, INVESTMENT DECK',
  },
  {
    id: 'footmarks',
    name: 'footmarks',
    work: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION',
  },
  {
    id: 'biznation',
    name: 'biznation',
    work: 'BRANDING, GRAPHIC DESIGN, WEB DESIGN, WEB DEVELOPMENT, WEB APP DEVELOPMENT',
  },
  {
    id: 'ascend',
    name: 'ascend',
    work: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION',
  },
  {
    id: 'rize',
    name: 'rize',
    work: 'BRANDING, UX, WEB DESIGN, WEB DEVELOPMENT, VIDEO PRODUCTION',
  },
  {
    id: 'nxt-comm',
    name: 'nxt comm',
    work: 'BRANDING, UX, WEB DESIGN, WEB DEVELOPMENT, ',
  },
  {
    id: 'color-club',
    name: 'color club',
    work: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT',
  },
  {
    id: 'fairmont',
    name: 'fairmont',
    work: 'VIDEO PRODUCTION, CREATIVE DIRECTION',
  },
  {
    id: 'moda-fc',
    name: 'moda fc',
    work: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, APPAREL DESIGN',
  },
  {
    id: 'veristone',
    name: 'veristone',
    work: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT',
  },
  {
    id: 'star-buds',
    name: 'star buds',
    work: 'BRANDING, WEB DESIGN, APP DEVELOPMENT',
  },
  {
    id: 'jaqor',
    name: 'jaqor',
    work: 'BRANDING, WEB DESIGN, WEB DEVELOPMENT, 3D DESIGN',
  },
];

const HomeWorkSection: FC<PropsWithStyle> = ({ style = {}, className = '' }: PropsWithStyle) => {
  const ref = useRef(null);
  const { width = 0 } = useComponentSize(ref);

  const isMobile = width < deviceSizes.S;

  const calculateResponsiveSize = (designedSize: ResponsiveProps<number>): string =>
    `${isMobile ? (designedSize.mobile / 375) * width : (designedSize.desktop / 1440) * width}px`;

  return (
    <HomeSectionTemplate
      imagePath={sectionDesignedStyle.title.imagePath.mobile}
      imagePathDesktop={sectionDesignedStyle.title.imagePath.desktop}
      designedStyle={sectionDesignedStyle}
      style={{ width: '100%', ...style }}
      className={className}
    >
      <HomeWorkTileListContainer ref={ref}>
        <HomeWorkTileList data={tileListData} style={{ width: '100%' }} isMobile={isMobile} />
      </HomeWorkTileListContainer>
      <div style={{ height: calculateResponsiveSize(sectionDesignedStyle.more.gap.top) }} />
      <HomeWorkMore
        isMobile={isMobile}
        style={{
          width: calculateResponsiveSize(sectionDesignedStyle.more.width),
          fontSize: calculateResponsiveSize(sectionDesignedStyle.more.text.size),
          paddingLeft: calculateResponsiveSize(sectionDesignedStyle.more.gap.left),
        }}
      />
      <div style={{ height: calculateResponsiveSize(sectionDesignedStyle.more.gap.bottom) }} />
    </HomeSectionTemplate>
  );
};

const HomeWorkTileListContainer = styled.div`
  width: 100%;
`;

export default HomeWorkSection;
