import React, { FC, useState, useRef } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import GatsbyImage from '../../gatsby/GatsbyImage';
import useClickOutsideHandler from '../../hooks/useClickOutsideHandler';
import HomeWorkTileOverlay from './HomeWorkTileOverlay';

interface HomeWorkTileProps extends PropsWithStyle {
  /** image path (grayscale) */
  imagePathGray: string;
  /** image path (color) */
  imagePathColor: string;
  /** name */
  name: string;
  /** work brief */
  work: string;
  /** navigation target */
  navTo: string;
  /** flag for mobile UX */
  isMobile?: boolean;
}

const HomeWorkTile: FC<HomeWorkTileProps> = ({
  imagePathGray,
  imagePathColor,
  name,
  work,
  isMobile = false,
  navTo,
  style = {},
  className = '',
}: HomeWorkTileProps) => {
  const [showLayout, setShowLayout] = useState(false);
  const ref = useRef(null);
  useClickOutsideHandler(ref, (): void => {
    // hide overlay when click outside the container on mobile
    if (isMobile) setShowLayout(false);
  });
  return (
    <HomeWorkTileContainer
      // style={{ cursor: showLayout ? 'default' : 'pointer', ...style }}
      style={style}
      className={className}
      ref={ref}
      onMouseEnter={(): void => {
        // show overlay when hover inside the container on desktop
        if (!isMobile) setShowLayout(true);
      }}
      onMouseLeave={(): void => {
        // hide overlay when hover outside the container on desktop
        if (!isMobile) setShowLayout(false);
      }}
      onClick={(): void => {
        // show overlay when click the container on mobile
        if (isMobile) setShowLayout(true);
      }}
    >
      <GatsbyImage
        imagePath={imagePathGray}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          visibility: showLayout ? 'hidden' : 'visible',
        }}
      />
      <GatsbyImage
        imagePath={imagePathColor}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          pointerEvents: 'none',
          visibility: showLayout ? 'visible' : 'hidden',
        }}
      />
      {showLayout && (
        <HomeWorkTileOverlay
          style={{ position: 'absolute' }}
          name={name}
          work={work}
          onClick={(): void => {
            navigate(navTo);
          }}
        />
      )}
    </HomeWorkTileContainer>
  );
};

export default HomeWorkTile;

const HomeWorkTileContainer = styled.div`
  position: relative;
  user-select: none;
`;
