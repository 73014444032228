import { MutableRefObject, useEffect } from 'react';

const useClickOutsideHandler = (
  ref: MutableRefObject<HTMLElement | null>,
  callback: () => void,
): void => {
  const documentClickEventHandler = (event: MouseEvent): void => {
    if (
      ref?.current &&
      event?.target &&
      ref.current !== event.target &&
      !ref.current.contains(event.target as Node)
    ) {
      callback();
    }
  };
  useEffect(() => {
    document.addEventListener('click', documentClickEventHandler);
    return (): void => {
      if (ref?.current) {
        document.removeEventListener('click', documentClickEventHandler);
      }
    };
  }, []);
};

export default useClickOutsideHandler;
