import React, { FC, useRef } from 'react';
import styled from 'styled-components';
import HomeWorkTileOverlayArrow from '../atoms/HomeWorkTileOverlayArrow';
import useComponentSize from '../../hooks/useComponentSize';

interface HomeWorkTileOverlayProps extends PropsWithStyle {
  /** name */
  name: string;
  /** work brief */
  work: string;
  /** click event */
  onClick?: () => void;
}

const HomeWorkTileOverlay: FC<HomeWorkTileOverlayProps> = ({
  name,
  work,
  onClick = (): void => {
    // do nothing
  },
  style = {},
  className = '',
}: HomeWorkTileOverlayProps) => {
  const ref = useRef(null);
  const { width = 0 } = useComponentSize(ref);
  return (
    <HomeWorkTileOverlayContainer style={style} className={className} ref={ref} onClick={onClick}>
      <HomeWorkTileOverlayContentWrapper>
        <HomeWorkTileOverlayWorkText
          style={{
            fontSize: `${(12 / 433) * width}px`,
            lineHeight: `${(14 / 433) * width}px`,
            letterSpacing: '0.09em',
          }}
        >
          {work}
        </HomeWorkTileOverlayWorkText>
        <HomeWorkTileOverlayNameText
          style={{
            fontSize: `${(24 / 433) * width}px`,
            lineHeight: `${(26 / 433) * width}px`,
            letterSpacing: '0.215em',
          }}
        >
          {name}
        </HomeWorkTileOverlayNameText>
        <Arrow />
      </HomeWorkTileOverlayContentWrapper>
    </HomeWorkTileOverlayContainer>
  );
};

const HomeWorkTileOverlayContainer = styled.div`
  cursor: pointer;
  width: ${(433 / 480) * 100}%;
  height: ${(207 / 246) * 100}%;
  left: ${(20 / 480) * 100}%;
  top: ${(24 / 246) * 100}%;
  background: rgba(0, 0, 0, 0.85);
  z-index: 3;
  overflow: hidden;
`;

const HomeWorkTileOverlayContentWrapper = styled.div`
  margin-top: ${(36 / 433) * 100}%;
  margin-left: ${(38 / 433) * 100}%;
  width: ${(347 / 433) * 100}%;
  height: ${(132 / 207) * 100}%;
  overflow: hidden;
  position: relative;
`;

const HomeWorkTileOverlayNameText = styled.p`
  text-transform: uppercase;
  position: absolute;
  left: 0;
  bottom: 0;
  font-family: 'PragmaticaExtended-Black';
`;

const HomeWorkTileOverlayWorkText = styled.p`
  text-transform: uppercase;
  position: absolute;
  left: 0;
  top: 0;
  font-family: 'Replica-Bold';
`;

const Arrow = styled(HomeWorkTileOverlayArrow)`
  text-transform: uppercase;
  position: absolute;
  right: 0;
  bottom: ${(5 / 132) * 100}%;
  width: ${(57 / 347) * 100}%;
  height: ${(15 / 132) * 100}%;
`;

export default HomeWorkTileOverlay;
