import React, { FC } from 'react';

interface HomeWorkTileOverlayArrowProps extends PropsWithStyle {
  /** icon fill color */
  color?: string;
}

const HomeWorkTileOverlayArrow: FC<HomeWorkTileOverlayArrowProps> = ({
  style = {},
  className = '',
  color = '#FFE42A',
}: HomeWorkTileOverlayArrowProps) => (
  <svg
    style={style}
    fill={color}
    className={className}
    viewBox="0 0 92 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77 0L92 11L77.1667 24L77 13.3333H0V9.66667H77V0Z"
    />
  </svg>
);

export default HomeWorkTileOverlayArrow;
